import * as React from "react"
import Header from "../components/Header";
import Footer from "../components/Footer";

// markup
const NotFoundPage = () => {
  return (
    <>
      <Header />
      <div className="error404">
        <h1>Page not found</h1>
      </div>
      <Footer />
    </>
  );
};

export default NotFoundPage
